import React, { useState } from "react"
import { graphql } from "gatsby"
import { UList, HList } from "./blocks"
import PersonIcon from "../../content/assets/zondicons/user.svg"
import PersonsIcon from "../../content/assets/zondicons/user-group.svg"
import { IconBox } from "./blocks"

export const AuthorName = ({ author, className = "" }) => (
  <div className={`text-primary-500 ${className}`}>{author.firstname}</div>
)

export const AuthorInformation = ({ author, className = "" }) => (
  <div className={`text-primary-500 ${className}`}>
    {author.firstname} {author.lastname}, {author.street}, {author.zipcode}{" "}
    {author.city}
  </div>
)

const selectItem = authors =>
  authors.length > 1 ? (
    <PersonsIcon className="mr-4" />
  ) : (
    <PersonIcon className="mr-4" />
  )

export const AuthorNames = ({ authors }) => (
  <IconBox icon={selectItem(authors)}>
    <HList
      items={authors}
      getKey={author => author.name}
      renderItem={author => (
        <AuthorName author={author} className="inline-block" />
      )}
      className="mb-2"
    />
  </IconBox>
)

export const ResonsibleAuthors = ({ authors, className }) => {
  const [visible, setVisible] = useState(false)
  return (
    <div
      className={`flex text-sm bg-secondary-100 p-3 pb-2 rounded-lg text-primary-500 fill-current ${className}`}
    >
      <div className="block w-full pt-1">
        <div className="inline-block mr-1">
          Inhaltlich verantwortlich für den Inhalt dieses Beitrags gemäß § 18
          Abs. 2 MStV:
        </div>
        {visible ? (
          <UList
            items={authors}
            getKey={author => author.name}
            renderItem={author => (
              <AuthorInformation author={author} className="" />
            )}
            className="list-disc ml-4"
          />
        ) : (
          <HList
            items={authors}
            getKey={author => author.name}
            renderItem={author => (
              <AuthorName author={author} className="inline-block" />
            )}
            className="inline-block mr-1 mb-2"
          />
        )}
      </div>
      <div
        className="w-20 font-bold ml-2 text-primary-700"
        onClick={() => setVisible(!visible)}
      >
        <button className="w-18 rounded-lg px-2 py-1 hover:bg-secondary-200 border border-solid border-primary-700">
          {" "}
          {visible ? "weniger" : "mehr"}
        </button>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment AuthorInformation on PersonsYaml {
    firstname
    lastname
    name
    street
    zipcode
    city
  }
`
