import React from "react"
import { graphql } from "gatsby"
import { renderAst } from "../utils/markdown.jsx"
import { H1, Max2xl, PX3, Max5xl } from "../components/blocks"
import Layout from "../components/layout.jsx"
import SEO, { NoIndex } from "../components/seo.js"
import DescribedPicture from "../components/described-picture"
import { ResonsibleAuthors } from "../components/author.jsx"

class PageTemplate extends React.Component {
  render() {
    const page = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        {
          (page.frontmatter.seo = "on" ? (
            <SEO
              title={page.frontmatter.title}
              description={page.frontmatter.description || page.excerpt}
            />
          ) : (
            <NoIndex lang="de" />
          ))
        }

        <Max5xl className="text-primary-900 py-3">
          <Max2xl className="">
            <PX3>
              <H1>{page.frontmatter.title}</H1>
              {/* <H2>{page.frontmatter.date}</H2> */}
            </PX3>
          </Max2xl>
          {page.fields.described_picture ? (
            <div className="py-3 sm:px-3">
              <DescribedPicture
                describedPicture={page.fields.described_picture}
              />
            </div>
          ) : (
            <></>
          )}
          {page.frontmatter.picture ? (
            <Max2xl>
              {/* <Img
                  className=""
                  fluid={page.frontmatter.picture.childImageSharp.fluid}
                /> */}
              {/* <img alt="" src={page.frontmatter.picture} /> */}
            </Max2xl>
          ) : (
            ""
          )}
          <Max2xl>
            <PX3 className="pt-6">{renderAst(page.htmlAst)}</PX3>
            <ResonsibleAuthors
              authors={page.frontmatter.responsible_authors}
              className="mt-6"
            />
          </Max2xl>
        </Max5xl>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      fields {
        slug
        described_picture {
          ...DescribedPicture
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        seo
        responsible_authors {
          ...AuthorInformation
        }
        picture {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
      }
    }
  }
`
