import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Date } from "./datetime"
import { PX3, Max2xl } from "./blocks"

export default ({ describedPicture, className = "" }) => (
  <div>
    <Img
      className={className}
      fluid={describedPicture.picture.childImageSharp.fluid}
      alt={describedPicture.description}
    />
    <Max2xl>
      <PX3 className="pt-1">
        <p className="inline-block w-full text-sm text-primary-600">
          {describedPicture.location
            ? describedPicture.location.city + ", "
            : ""}
          <Date className="inline-block" datetime={describedPicture.date} />
          {". "}
          {describedPicture.description}
          {describedPicture.photographer ? (
            <span className="float-right ">
              Foto: {describedPicture.photographer.firstname}{" "}
              {describedPicture.photographer.lastname}
            </span>
          ) : (
            ""
          )}
        </p>
      </PX3>
    </Max2xl>
  </div>
)

export const query = graphql`
  fragment DescribedPicture on PicturesYaml {
    picture {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    location {
      city
    }
    description
    date
    photographer {
      firstname
      lastname
    }
  }
`
